import Image from "next/image";
import {useTranslation} from "ni18n";
import React, {useMemo} from "react";

import {useFetchCareEntries} from "../../../queries/useFetchCareEntries";
import {useFeaturedCareSlugs} from "../../../utils/getFeaturedCares";
import ContentArea from "../../composition/ContentArea";
import Section from "../../composition/Section";
import {CareEntryRowVariant} from "../CareEntryRow";
import Icon from "../Icon";
import {useSendDefaultResultsViewedEvent} from "../Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import CareDiscoverySearch from "../Reason/CareDiscoverySearch/CareDiscoverySearch";

const Line2 = ({children}: {children: React.ReactChild}) => (
  <p className="white text-xl lg:text-[28px] lg:leading-[34px] font-cm tracking-tight">
    {children}
  </p>
);

const Hero: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();

  const featuredCareSlugs = useFeaturedCareSlugs();
  const defaultResultsQuery = useFetchCareEntries(featuredCareSlugs);

  const viewId = useSendDefaultResultsViewedEvent({
    results: defaultResultsQuery.data,
    isLoading: !defaultResultsQuery.isSettled,
  });

  const noResults = defaultResultsQuery.isSettled && defaultResultsQuery.data.length === 0;
  const loadingRowCount = !defaultResultsQuery.isSettled ? featuredCareSlugs.length : undefined;

  const widget = useMemo(
    () => (
      <CareDiscoverySearch
        defaultResults={defaultResultsQuery.data}
        defaultResultsLoadingCount={loadingRowCount}
        dialogTitle={i18n.t("Book an Appointment")}
        defaultListRowVariant={CareEntryRowVariant.SM}
        viewId={viewId}
        hideRegionSelector
        hideDefaultListController
        hideDefaultListGradient
        doNotReportDefaultResultsOnMount
        defaultResultsFailed={noResults}
      />
    ),
    [defaultResultsQuery.data, loadingRowCount, i18n, viewId, noResults],
  );

  return (
    <>
      <Section className="relative pt-40 pb-20 lg:pt-40 lg:pb-20 flex flex-col bg-gray-300">
        <Image
          src="/static/img/home/mac-n-cheese.jpg"
          alt={i18n.t("A mother eating with her daughter")}
          className="mix-blend-multiply object-cover object-[left_calc(20%)_bottom_calc(50%)] lg:object-right"
          priority
          fill
        />
        <ContentArea className="flex gap-8 justify-between items-end relative">
          <div className="flex flex-col lg:mb-40">
            <h1 className="text-white maxw30 mb-4 font-cm text-3xl lg:text-5xl">
              {i18n.t("This is care\nas it should be")}
            </h1>
            <Line2>{i18n.t("Easy scheduling.")}</Line2>
            <Line2>{i18n.t("Caring providers.")}</Line2>
            <Line2>{i18n.t("In person and in our app.")}</Line2>
          </div>
          <span className="absolute lg:bottom-0 left-0 font-ttr text-gray-50 block -bottom-8 text-sm lg:text-base">
            {i18n.t("Scroll to learn more")}
            <Icon icon="arrow-right3" className="rotate-90 inline-block ml-2 text-xs" />
          </span>
          <div className="hidden lg:block w-[434px]">
            <div className="bg-white rounded-xl p-6 flex flex-col justify-between">{widget}</div>
          </div>
        </ContentArea>
      </Section>
      <Section className="lg:hidden">
        <ContentArea className="py-6">{widget}</ContentArea>
      </Section>
    </>
  );
};

export default React.memo(Hero);
